<template>
  <!-- 异常日志详情页面 -->
  <div class="positionT0L0">
    <a-form-model
      :model="form"
      labelAlign="left"
      :label-col="{ span: 2 }"
      :wrapper-col="{ span: 16 }"
    >
      <a-form-model-item label="异常来源">
        <span v-show="form.code == 1">app-consumer</span>
        <span v-show="form.code == 2">app-provider</span>
        <span v-show="form.code == 3">admin-consumer</span>
        <span v-show="form.code == 4">admin-provider</span>
      </a-form-model-item>
      <a-form-model-item label="异常标题">
        <span>{{ form.title }}</span>
      </a-form-model-item>
      <a-form-model-item label="异常类路径">
        <span>{{ form.classPath }}</span>
      </a-form-model-item>
      <a-form-model-item label="异常方法">
        <span>{{ form.method }}</span>
      </a-form-model-item>
      <a-form-model-item label="异常发生的行号">
        <span>{{ form.lineNumber }}</span>
      </a-form-model-item>
      <a-form-model-item label="异常内容">
        <Ueditor :content="form.content" @editorFn="editorFn" />
      </a-form-model-item>
    </a-form-model>
    <!-- 底部 -->
    <FooterToolBar :collapsed="sideCollapsed">
      <a-button @click="() => $router.go(-1)">返回</a-button>
    </FooterToolBar>
  </div>
</template>

<script>
import { baseMixin } from "@/store/app-mixin";
import FooterToolBar from "@/components/FooterToolbar";
import { GetExceptionLogApi } from "@/request/api/systemManage";
import Ueditor from "@/components/ueditor/ueditor";
export default {
  mixins: [baseMixin],
  components: { Ueditor, FooterToolBar },
  created() {
    GetExceptionLogApi({ id: this.$route.params.id }).then(({ code, data }) => {
      if (code == 200) {
        this.form = data;
      }
    });
  },
  data() {
    return {
      form: {
        code: "",
        title: "",
        classPath: "",
        method: "",
        lineNumber: null,
        content: "",
      },
    };
  },
  methods: {
    // 富文本的函数
    editorFn(value) {
      this.form.content = value;
    },
  },
};
</script>
 
<style lang="less" scoped>
/deep/.ant-form-item-control {
  line-height: unset;
}
</style>